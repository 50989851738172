import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { Remarkable } from 'remarkable';

import Layout from '../components/Layout'
import TitleBanner from '../components/TitleBanner'
import { Container } from '../components/Theme'
import Contact from '../components/Contact'  


const md = new Remarkable();

const PanelContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media (min-width: 600px){
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  }
`;

const BackgroundWrapper = styled.div`
background-image: url(${props => props.image});
background-size: cover;
background-position: center;
`;

const ContainerWithBackground = styled(Container)`
padding: 100px 1rem;
div {
  background-color: hsla(0,0%,100%,0.8);
  padding: 2rem;
  }
`;

const ContactWrapper = styled.div`
background-color: rgba(0,94,107,1);
color: #fff;
form, button {
  margin-bottom:0;
}
`;

export default class ServiceLayout extends Component {

  render(){

    // Pull data from graphql
    const {markdownRemark: post} = this.props.data;

    return(
      <Layout>
        <Helmet
          title={`${post.frontmatter.title} - Metro Communications`}
          meta={[
            { name: 'description', content: post.excerpt },
            { name: 'keywords', content: `` },
          ]}
        />
        <TitleBanner
          title={post.frontmatter.title}
          image={post.frontmatter.image.childImageSharp.fluid.src}
        />
        <PanelContainer>
            <div dangerouslySetInnerHTML={{__html: post.html}}/>
            {post.frontmatter.introImage && <Img fixed={post.frontmatter.introImage.childImageSharp.fixed} alt={`${post.frontmatter.title} intro image`}/>}
        </PanelContainer>
        <BackgroundWrapper image={post.frontmatter.problemImage.childImageSharp.fixed.src}>
          <ContainerWithBackground>
            <div dangerouslySetInnerHTML={{__html: md.render(post.frontmatter.problem)}}></div>
          </ContainerWithBackground>
        </BackgroundWrapper>
        
        <PanelContainer>
          <div dangerouslySetInnerHTML={{__html: md.render(post.frontmatter.solution)}}/>
          {post.frontmatter.solutionImage && <Img fixed={post.frontmatter.solutionImage.childImageSharp.fixed} alt={`${post.frontmatter.title} intro image`}/>}
        </PanelContainer>
        <ContactWrapper>
          <Contact page={post.frontmatter.title}/>
        </ContactWrapper>
      </Layout>
    )
  }
}

export const serviceTemplateQuery = graphql`
  query serviceTemplateQuery($id: String!) {
    markdownRemark(id: {eq: $id}){
      id
      excerpt
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
        introImage {
          childImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        problem
        problemImage {
          childImageSharp {
            fixed(width: 1920) {
              src
            }
          }
        }
        solution
        solutionImage {
          childImageSharp {
            fixed(width: 500) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`